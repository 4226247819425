<template>
  <form class="vx-row">
    <div class="vx-col w-full mb-base">
      <vx-card>
        <h4 class="mb-4">ข้อมูลผู้ใช้น้ำ</h4>
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>ชื่อสมาชิก</span>
                <span class="text-red align-text-bottom">*</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" v-validate="'required'" name="name" v-model="memberData.M04"/>
                <span class="text-danger text-sm" v-show="errors.has('name')">กรุณากรอกชื่อ-นามสกุลสมาชิก</span>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>เลขที่บัตรประชาชน</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" v-model="memberData.M08"/>
              </div>
            </div>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>เบอร์โทรศัพท์</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" v-model="memberData.M07"/>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-1/2 w-full mb-2">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>บ้านเลขที่</span>
                <span class="text-red align-text-bottom">*</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" v-validate="'required'" name="addressNo" v-model="memberData.M05"/>
                <span class="text-danger text-sm" v-show="errors.has('addressNo')">กรุณากรอกบ้านเลขที่</span>
              </div>
            </div>
          </div>
          <div class="vx-col sm:w-1/2 w-full mb-2">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>ที่อยู่</span>
                <span class="text-red align-text-bottom">*</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" v-validate="'required'" name="villageNo" v-model="memberData.M06"/>
                <span class="text-danger text-sm" v-show="errors.has('villageNo')">กรุณากรอกหมู่</span>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-1/2 w-full mb-2">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>ซอย</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" name="soi" v-model="memberData.soi"/>
                <span class="text-danger text-sm" v-show="errors.has('soi')">กรุณากรอกซอย</span>
              </div>
            </div>
          </div>
          <div class="vx-col sm:w-1/2 w-full mb-2">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>ถนน</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" name="road" v-model="memberData.road"/>
                <span class="text-danger text-sm" v-show="errors.has('road')">กรุณากรอกถนน</span>
              </div>
            </div>
          </div>
        </div>
        <h4 class="mt-6 mb-5">เกี่ยวกับมิเตอร์และมาตรวัด</h4>
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>หน่วยมิเตอร์ล่าสุด</span>
                <span class="text-red align-text-bottom">*</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input type="number" class="w-full" v-validate="'required'" name="lastUnitMeter" v-model="memberData.M09"/>
                <span class="text-danger text-sm" v-show="errors.has('lastUnitMeter')">กรุณากรอกหน่วยมิเตอร์ล่าสุด</span>
              </div>
            </div>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>ขนาดมิเตอร์</span>
                <span class="text-red align-text-bottom">*</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <v-select :options="meters" @input="setUseWaterRate" v-validate="'required'" name="meter" :clearable="false" v-model="selectedM10" class="mb-4 md:mb-0"/>
                <span class="text-danger text-sm" v-show="errors.has('meter')">กรุณาเลือกขนาดมิเตอร์</span>
              </div>
            </div>
          </div>
          <div class="mt-6 mb-5 vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>หลักหน้ามาตรวัด (หลัก)</span>
                <span class="text-red align-text-bottom">*</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input type="number" class="w-full" v-validate="'required'" name="useWaterRate" v-model="memberData.meter_size"/>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/2 w-full">
                <span>อัตราค่าธรรมเนียมใช้น้ำ (บาท)</span>
                <span class="text-red align-text-bottom">*</span>
              </div>
              <div class="vx-col sm:w-1/2 w-full">
                <vs-input type="number" class="w-full" v-validate="'required'" name="useWaterRate" v-model="memberData.M11" readonly/>
                <span class="text-danger text-sm" v-show="errors.has('useWaterRate')">กรุณากรอกอัตราค่าธรรมเนียมใช้น้ำ</span>
              </div>
            </div>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/2 w-full">
                <span>ประเภทผู้ใช้น้ำ (สูตรค่าน้ำ)</span>
                <span class="text-red align-text-bottom">*</span>
              </div>
              <div class="vx-col sm:w-1/2 w-full">
                <v-select v-validate="'required'" name="rate" :options="rates" :clearable="false" v-model="selectedM12" class="mb-4 md:mb-0"/>
                <span class="text-danger text-sm" v-show="errors.has('rate')">กรุณาเลือกประเภทผู้ใช้น้ำ</span>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/2 w-full">
                <span>กลุ่ม/หน่วยงานที่ใช้น้ำ</span>
                <span class="text-red align-text-bottom">*</span>
              </div>
              <div class="vx-col sm:w-1/2 w-full">
                <v-select @input="setGroup" :options="groups" v-validate="'required'" name="groups" :clearable="false" v-model="selectedM13" class="mb-4 md:mb-0"/>
                <span class="text-danger text-sm" v-show="errors.has('groups')">กรุณาเลือกกลุ่มหรือหน่วยงานที่ใช้น้ำำ</span>
              </div>
            </div>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>เลขประจำมิเตอร์</span>
                <span class="text-red align-text-bottom">*</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" v-validate="'required'" name="numberOfMeter" v-model="meterNumberM27"/>
                <span class="text-danger text-sm" v-show="errors.has('numberOfMeter')">กรุณากรอกเลขประจำมิเตอร์</span>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>พื้นที่จัดเก็บ</span>
                <span class="text-red align-text-bottom">*</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <v-select @input="setArea" :options="areas" :value="areas" v-validate="'required'" name="area" :clearable="false" v-model="selectedM02" class="mb-4 md:mb-0"/>
                <span class="text-danger text-sm" v-show="errors.has('area')">กรุณาเลือกพื้นที่จัดเก็บ</span>
              </div>
            </div>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>เส้นทางจัดเก็บ</span>
                <span class="text-red align-text-bottom">*</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <v-select @input="setRoute" :options="routes" :value="routes" v-validate="'required'" name="root" :clearable="false" v-model="selectedM03" class="mb-4 md:mb-0"/>
                <span class="text-danger text-sm" v-show="errors.has('root')">กรุณาเลือกเส้นทางจัดเก็บ</span>
              </div>
            </div>
          </div>
        </div>
        <h4 class="mt-6 mb-5">สถานะและค่าธรรมเนียม</h4>
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-1/5 w-full">
            <span>วันที่ขอใช้น้ำ</span>
            <span class="text-red align-text-bottom">*</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <datepicker v-validate="'required'" name="dateUseWater" :language="languages[language]" format="dd-MM-yyyy" v-model="memberData.M14"></datepicker>
            <span class="text-danger text-sm" v-show="errors.has('dateUseWater')">กรุณาเลือกวันที่ขอใช้น้ำ</span>
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/2 w-full">
                <span>ค่าจัดเก็บขยะมูลฝอย (บาท)</span>
              </div>
              <div class="vx-col sm:w-1/2 w-full">
                <vs-input type="number" class="w-full" v-model="memberData.M15"/>
              </div>
            </div>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/2 w-full">
                <span>ค่าธรรมเนียมบำบัดน้ำเสีย (บาท/%)</span>
              </div>
              <div class="vx-col sm:w-1/2 w-full">
                <vs-input type="number" class="w-full" v-model="memberData.M16"/>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>วิธีการชำระเงิน</span>
                <span class="text-red align-text-bottom">*</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <v-select @input="setPayBy" v-validate="'required'" name="payBy" :options="paybys" :clearable="false" v-model="selectedM17" class="mb-4 md:mb-0"/>
                <span class="text-danger text-sm" v-show="errors.has('payBy')">กรุณาเลือกวิธีการชำระเงิน</span>
              </div>
            </div>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/2 w-full">
                <span>เลขที่บัญชี (ผ่านบัญชีธนาคาร)</span>
              </div>
              <div class="vx-col sm:w-1/2 w-full">
                <vs-input class="w-full" v-model="memberData.M18" :disabled="disabledPayBy"/>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-1/3 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>ชื่อธนาคาร</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" v-model="memberData.M19" :disabled="disabledPayBy"/>
              </div>
            </div>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>สาขา</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" v-model="memberData.M20" :disabled="disabledPayBy"/>
              </div>
            </div>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/2 w-full">
                <span>ค่าธรรมเนียม</span>
              </div>
              <div class="vx-col sm:w-1/2 w-full">
                <vs-input type="number" class="w-full" v-model="memberData.M21" :disabled="disabledPayBy"/>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>ประเภทผู้ได้ส่วนลด</span>
                <span class="text-red align-text-bottom">*</span>
               </div>
              <div class="vx-col sm:w-2/3 w-full">
                <v-select @input="setDiscountM22"  v-validate="'required'" name="typeOfDiscount" :options="discount" :clearable="false" v-model="selectedM22" class="mb-4 md:mb-0"/>
                <span class="text-danger text-sm" v-show="errors.has('typeOfDiscount')">กรุณาเลือกประเภทผู้ได้ส่วนลด</span>
              </div>
            </div>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>ส่วนลดที่ได้</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input type="number" class="w-full" v-model="discountValue" readonly/>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>วันที่ตัดน้ำ</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <datepicker :language="languages[language]" format="dd MMMM yyyy" v-model="memberData.cutOffDate"></datepicker>
              </div>
            </div>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>วันที่ประสานมาตร</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <datepicker :language="languages[language]" format="dd MMMM yyyy" v-model="memberData.mergeMeterDate"></datepicker>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col sm:w-1/5 w-full">
            <span>ต้องการออก Vat หรือไม่</span>
          </div>
          <div class="vx-col sm:w-3/5 w-full">
            <vs-checkbox class="inline-flex" v-model="selectedVat">ต้องการ Vat</vs-checkbox>
          </div>
        </div>
        <h4 class="mt-6 mb-5">สถานะการใช้น้ำ/ถูกงดใช้น้ำ</h4>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>สถานะการใช้งาน</span>
                <span class="text-red align-text-bottom">*</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <v-select v-validate="'required'" name="status" @input="setDateStatus" :options="unuses" :clearable="false" v-model="selectedM23" class="mb-4 md:mb-0"/>
                <span class="text-danger text-sm" v-show="errors.has('status')">กรุณาเลือกสถานะการใช้งาน</span>
              </div>
            </div>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-2/5 w-full">
                <span>วันที่ยกเลิกหรืองดจ่ายน้ำ</span>
              </div>
              <div class="vx-col sm:w-3/5 w-full">
                <datepicker name="cancelOrPayment" :language="languages[language]" format="dd MMMM yyyy" v-model="memberData.M24" :disabled="disabledStatus"></datepicker>
                <span class="text-danger text-sm" v-show="errors.has('cancelOrPayment')">กรุณาเลือกวันที่ยกเลิกหรืองดจ่ายน้ำ</span>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-1/5 w-full">
            <span>หมายเหตุ</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <vs-input type="ะำปะ" class="w-full" v-model="memberData.remark"/>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col sm:w-1/6 w-full">
            <span>แจ้งปัญหา</span>
          </div>
          <div class="vx-col sm:w-5/6 w-full">
            <div class="vx-row mb-5">
              <div class="vx-col sm:w-1/3 w-full">
                <vs-checkbox v-model="checkBoxIssue" vs-value="1">1.มาตรอยู่ในบ้าน</vs-checkbox>
              </div>
              <div class="vx-col sm:w-1/3 w-full">
                <vs-checkbox v-model="checkBoxIssue" vs-value="2">2.มาตรชำรุด</vs-checkbox>
              </div>
              <div class="vx-col sm:w-1/3 w-full">
                <vs-checkbox v-model="checkBoxIssue" vs-value="3">3.น้ำรั่วภายในบ้าน</vs-checkbox>
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <vs-checkbox v-model="checkBoxIssue" vs-value="4">4.หน้าปัทม์มองไม่เห็น</vs-checkbox>
              </div>
              <div class="vx-col sm:w-1/3 w-full">
                <vs-checkbox v-model="checkBoxIssue" vs-value="5">5.มีสิ่งกีดขวาง</vs-checkbox>
              </div>
              <div class="vx-col sm:w-1/3 w-full">
                <div style="display: table-cell; width: 80px">
                  <vs-checkbox v-model="checkBoxIssue" vs-value="6">6.อื่นๆ</vs-checkbox>
                </div>
                <div style="display: table-cell;" v-if="disabledOtherIssues">
                  <vs-input class="w-full" v-model="memberData.otherIssues" :disabled="disabledOtherIssues"/>
                </div>
                <div style="display: table-cell;" v-else>
                  <vs-input class="w-full" v-validate="'required'" name="otherIssues" v-model="memberData.otherIssues" />
                  <span class="text-danger text-sm" v-show="errors.has('otherIssues')">กรุณากรอกแจ้งปัญหาอื่นๆ</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mt-6">
          <div class="vx-col sm:w-1/2 w-full text-right">
            <vs-button color="danger" type="filled" class="mb-2" @click="$router.push('/user')">ยกเลิก</vs-button>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <vs-button class="mb-2" :disabled="submitDisabled" @click="submitData()">บันทึก</vs-button>
          </div>
        </div>
      </vx-card>
    </div>
  </form>
</template>
<script>
  import vSelect from 'vue-select'
  import Datepicker from 'vuejs-datepicker';
  import moduleGroup from "@/store/group/moduleGroup.js";
  import moduleMember from "@/store/member/moduleMember.js";
  import moduleArea from "@/store/area/moduleArea.js";
  import moduleRoute from "@/store/route/moduleRoute.js";
  import moduleMeter from "@/store/meter/moduleMeter.js";
  import moduleDiscount from "@/store/discount/moduleDiscount.js";
  import modulePayby from "@/store/payby/modulePayby.js";
  import moduleUnuse from "@/store/unuse/moduleUnuse.js";
  import moduleRate from "@/store/rate/moduleRate.js";
  import * as lang from 'vuejs-datepicker/src/locale';
  import moment from "moment";

  export default {
    components: {
      Datepicker,
      vSelect
    },
    data() {
      return {
        memberData: {
          "M01": '',
          "M02": '',
          "M03": '',
          "M04": '',
          "M05": '',
          "M06": '',
          "M07": '',
          "M08": '',
          "M09": '',
          "M10": '',
          "M11": '',
          "M12": '',
          "M13": '',
          "M14": '',
          "M15": '0',
          "M16": '0',
          "M17": '',
          "M18": '',
          "M19": '',
          "M20": '',
          "M21": '',
          "M22": '',
          "M23": '',
          "M24": '',
          "M25": 0,
          "M26": '',
          "M27": '',
          "M28": '',
          "M29": '',
          "M30": '',
          "M31": 0,
          "M32": '',
          "M33": 0,
          "meter_size": 4,
          "org_id": '',
          "soi": '',
          "road": '',
          "remark": '',
          "issues": [],
          "otherIssues": '',
          "cutOffDate": '',
          "mergeMeterDate": ''
        },
        meterNumberM27: '',
        language: 'th',
        languages: lang,
        submitDisabled: false,
        isBusy: false,
        date: null,
        selectedVat: false,
        discountValue: '',
        selectedM02: {label: '', value: ''},
        selectedM03: {label: '', value: ''},
        selectedM10: {label: '', value: ''},
        selectedM12: {label: '', value: ''},
        selectedM13: {label: '', value: ''},
        selectedM17: {label: '', value: ''},
        selectedM22: {label: '', value: ''},
        selectedM23: {label: '', value: ''},
        disabledPayBy: false,
        disabledStatus: true,
        roleFilter: {label: '1', value: '1'},
        roleOptions: [{label: '1', value: '1'}, {label: '2', value: '2'}, {label: '3', value: '3'}, {
          label: '4',
          value: '4'
        },],
        checkBoxIssue: [],
        disabledOtherIssues: true
      }
    },
    computed: {
      currentPage() {
        if (this.isMounted) {
          return this.$refs.table.currentx
        }
        return 0
      },
      areas() {
        let options = [];
        this.$store.state.area.items.forEach(function (item) {
          options.push({
            'label': item.A02,
            'value': item.A01
          });
        });
        return options;
      },
      routes() {
        let options = [];
        this.$store.state.route.items.forEach(function (item) {
          options.push({
            'label': item.R02,
            'value': item.R01
          });
        });
        return options;
      },
      meters() {
        let options = [];
        this.$store.state.meter.items.forEach(function (item) {
          options.push({
            'label': item.MS02,
            'value': item.MS01,
            'data': item
          });
        });
        return options;
      },
      discount() {
        let options = [];
        this.$store.state.discount.items.forEach(function (item) {
          options.push({
            'label': item.MR02,
            'value': item.MR01,
            'data': item
          });
        });
        return options;
      },
      paybys() {
        let options = [];
        this.$store.state.payby.items.forEach(function (item) {
          options.push({
            'label': item.pb02,
            'value': item.pb01
          });
        });
        return options;
      },
      unuses() {
        let options = [];
        this.$store.state.unuse.items.forEach(function (item) {
          options.push({
            'label': item.U02,
            'value': item.U01
          });
        });
        return options;
      },
      rates() {
        let options = [];
        this.$store.state.rate.names.forEach(function (item) {
          options.push({
            'label': item.metertype_name,
            'value': item.metertype_id
          });
        });
        return options;
      },
      groups() {
        let options = [];
        this.$store.state.group.items.forEach((item) => {
          options.push({
            'label': item.MG02,
            'value': item.MG01
          });
        });
        return options;
      },
      member() {
        return this.$store.state.member.item;
      },
      queriedItems() {
        return this.$refs.table ? this.$refs.table.queriedResults.length : this.products.length;
      }
    },
    watch: {
      checkBoxIssue(val) {
        let flag = false
        if(val.length !== 0) {
          for(let i = 0; i < val.length; i++) {
            if(val[i] === '6') {
              flag = true
            }
          }
        }
        if(flag) {
          this.disabledOtherIssues = false
        } else {
          this.disabledOtherIssues = true
          this.memberData.otherIssues = ''
        }
      }
    },
    methods: {
      async init() {
        let member = JSON.parse(localStorage.getItem("memberData"));
        if (member == null) {
          await this.noLocal();
        }else {
          this.memberData = await member;
          await this.setLocal();
        }
      },
      async noLocal() {
        if (this.$store.state.area.items.length > 0) {
          this.selectedM02 = {
            'label': await this.$store.state.area.items[0].A02,
            'value': await this.$store.state.area.items[0].A01
          };
        }
        if (this.$store.state.route.items.length > 0) {
          this.selectedM03 = {
            'label': await this.$store.state.route.items[0].R02,
            'value': await this.$store.state.route.items[0].R01
          };
        }
        if (this.$store.state.meter.items.length > 0) {
          this.selectedM10 = {
            'label': await this.$store.state.meter.items[0].MS02,
            'value': await this.$store.state.meter.items[0].MS01,
            'data': await this.$store.state.meter.items[0]
          };
          this.setUseWaterRate(this.selectedM10);
        }
        if (this.$store.state.rate.names.length > 0) {
          this.selectedM12 = {
            'label': await this.$store.state.rate.names[0].metertype_name,
            'value': await this.$store.state.rate.names[0].metertype_id
          };
        }
        if (this.$store.state.group.items.length > 0) {
          this.selectedM13 = {
            'label': await this.$store.state.group.items[0].MG02,
            'value': await this.$store.state.group.items[0].MG01
          };
        }
        if (this.$store.state.payby.items.length > 0) {
          this.selectedM17 = {
            'label': await this.$store.state.payby.items[0].pb02,
            'value': await this.$store.state.payby.items[0].pb01
          };
        }
        if (this.$store.state.discount.items.length > 0) {
          this.selectedM22 = {
            'label': await this.$store.state.discount.items[0].MR02,
            'value': await this.$store.state.discount.items[0].MR01,
            'data': await this.$store.state.discount.items[0]
          };
          this.setDiscountM22(this.selectedM22);
        }
        if (this.$store.state.unuse.items.length > 0) {
          this.selectedM23 = {
            'label': await this.$store.state.unuse.items[0].U02,
            'value': await this.$store.state.unuse.items[0].U01
          };
        }
        this.setPayBy(this.selectedM17);
        this.$vs.loading.close()
      },
      setLocal() {
        this.memberData.M04 = '';
        this.memberData.M05 = '';
        this.memberData.M06 = '';
        this.memberData.M07 = '';
        this.memberData.M08 = '';
        this.memberData.M14 = moment().format('DD MMMM YYYY')
        this.memberData.soi = '';
        this.memberData.road = '';
        this.memberData.remark = '';
        this.setRoute(this.memberData.M02);
        this.setArea(this.memberData.M03);
        this.setMeter(this.memberData.M10);
        this.setRate(this.memberData.M12);
        this.setGroup(this.memberData.M13);
        this.setPaybyData(this.memberData.M17);
        this.setDiscount(this.memberData.M22);
        this.setUnuse(this.memberData.M23);
        this.setDateStatus(this.memberData.M23);
        if (this.memberData.M26 === 'True') {
          this.selectedVat = true;
        } else {
          this.selectedVat = false;
        }
        this.meterNumberM27 = this.memberData.M27;
        if (this.memberData.issues !== undefined && this.memberData.issues.length !== 0) {
          let array = []
          for(let i = 0; i < this.memberData.issues.length; i++) {
            array.push(this.memberData.issues.toString())
          }
          this.checkBoxIssue = array
        }
        if (this.memberData.cutOffDate !== undefined) {
          this.cutOffDate = this.memberData.cutOffDate
        }
        if (this.memberData.mergeMeterDate !== undefined) {
          this.mergeMeterDate = this.memberData.mergeMeterDate
        }
        this.$vs.loading.close()
      },
      async submitData() {
        await this.$validator.validateAll().then(result => {
          if(result) {
            this.submitDisabled = true
            this.memberData.M02 = this.selectedM02.value;
            this.memberData.M03 = this.selectedM03.value;
            this.memberData.M10 = this.selectedM10.value;
            this.memberData.M11 = parseFloat(this.memberData.M11);
            this.memberData.M12 = this.selectedM12.value;
            this.memberData.M13 = this.selectedM13.value;
            this.memberData.M15 = parseFloat(this.memberData.M15);
            this.memberData.M17 = this.selectedM17.value;
            this.memberData.M22 = this.selectedM22.value;
            this.memberData.M23 = this.selectedM23.value;
            this.memberData.M25 = 0.0;
            this.memberData.M27 = this.meterNumberM27;
            this.memberData.M32 = '0';
            if (this.selectedVat === true) {
              this.memberData.M26 = 'True';
            } else {
              this.memberData.M26 = 'False';
            }
            if (this.selectedM23.value === '01') {
              this.memberData.M24 = '';
            }
            if(this.checkBoxIssue.length !== 0) {
              let array = []
              let countOther = false
              for(let i = 0; i < this.checkBoxIssue.length; i++) {
                array.push(parseInt(this.checkBoxIssue[i]))
                if(this.checkBoxIssue[i] === '6') {
                  countOther = true
                }
              }
              this.memberData.issues = array
              if(!countOther) {
                this.memberData.otherIssues = ''
              }
            }
            localStorage.setItem('memberData', JSON.stringify(this.memberData));
            this.$store.dispatch("member/addItem", this.memberData).then((res) => {
              if (res.status === 201 || res[0].status === 201) {
                this.submitDisabled = false
                this.successDialog();
                this.init()
              } else {
                this.failDialog();
              }
            }).catch((error) => {
              this.failDialog();
            });
          }else{
            this.warningDialog();
          }
        });
      },
      setDateStatus(item) {
        if (item === '01' || item.value === '01') {
          this.disabledStatus = true;
          this.memberData.M24 = '';
        } else {
          this.disabledStatus = false;
          this.memberData.M24 = new Date();
        }
      },
      setPayBy(item) {
        if (item.value === '01') {
          this.memberData.M18 = '';
          this.memberData.M19 = '';
          this.memberData.M20 = '';
          this.memberData.M21 = '';
          this.disabledPayBy = true;
        } else {
          this.memberData.M18 = '';
          this.memberData.M19 = '';
          this.memberData.M20 = '';
          this.memberData.M21 = '';
          this.disabledPayBy = false;
        }
      },
      setPaybyData(item) {
        let data = {label: '', value: ''};
        this.$store.state.payby.items.forEach(function (payby) {
          if (item == payby.pb01) {
            data = {
              'label': payby.pb02,
              'value': payby.pb01
            };
          }
        });
        this.selectedM17 = data;
        this.setPayBy(this.selectedM17);
      },
      setRoute(item) {
        let data = {label: '', value: ''};
        let routeItem;
        if (item.value === undefined) {
          routeItem = item;
        } else {
          routeItem = item.value;
        }
        this.$store.state.route.items.forEach(function (route) {
          if (routeItem == route.R01) {
            data = {
              'label': route.R02,
              'value': route.R01
            };
          }
        });
        this.selectedM03 = data;
      },
      setArea(item) {
        let data = {label: '', value: ''};
        let areaItem;
        if (item.value === undefined) {
          areaItem = item;
        } else {
          areaItem = item.value;
        }
        this.$store.state.area.items.forEach(function (area) {
          if (areaItem === area.A01) {
            data = {
              'label': area.A02,
              'value': area.A01
            };
          }
        });
        this.selectedM02 = data;
      },
      setMeter(item) {
        let data = {label: '', value: ''};
        this.$store.state.meter.items.forEach(function (meter) {
          if (item === meter.MS01) {
            data = {
              'label': meter.MS02,
              'value': meter.MS01,
              'data': meter
            };
          }
        });
        this.selectedM10 = data;
        this.setUseWaterRate(this.selectedM10);
      },
      setDiscount(item) {
        let data = {label: '', value: ''};
        this.$store.state.discount.items.forEach(function (discount) {
          if (item === discount.MR01) {
            data = {
              'label': discount.MR02,
              'value': discount.MR01,
              'data': discount
            };
          }
        });
        this.selectedM22 = data;
        this.setDiscountM22(this.selectedM22);
      },
      setUnuse(item) {
        let data = {label: '', value: ''};
        this.$store.state.unuse.items.forEach(function (unuse) {
          if (item === unuse.U01) {
            data = {
              'label': unuse.U02,
              'value': unuse.U01
            };
          }
        });
        this.selectedM23 = data;
      },
      setRate(item) {
        let data = {label: '', value: ''};
        this.$store.state.rate.names.forEach(function (rate) {
          if (item === rate.metertype_id) {
            data = {
              'label': rate.metertype_name,
              'value': rate.metertype_id
            };
          }
        });
        this.selectedM12 = data;
      },
      setGroup(item) {
        let data = {label: '', value: ''};
        let groupItem;
        if (item === undefined || item.value === undefined) {
          groupItem = item;
        } else {
          groupItem = item.value;
        }
        this.$store.state.group.items.forEach((group) => {
          if (groupItem == group.MG01) {
            data = {
              'label': group.MG02,
              'value': group.MG01
            };
          }
        });
        this.selectedM13 = data;
      },
      setUseWaterRate(item) {
        this.memberData.M11 = item.data.MS03;
      },
      setDiscountM22(item) {
        this.discountValue = item.data.MR03;
      },
      warningDialog() {
        this.$vs.dialog({
          type: 'alert',
          color: 'warning',
          title: `คำเตือน`,
          text: 'กรุณากรอกเข้อมูลให้ครบ',
          acceptText: 'ปิด'
        })
      },
      failDialog() {
        this.$vs.dialog({
          type: 'alert',
          color: 'danger',
          title: `ผิดพลาด`,
          text: 'สร้างผู้ใช้น้ำไม่สำเร็จ',
          acceptText: 'ปิด'
        })
      },
      successDialog() {
        this.$vs.dialog({
          type: 'alert',
          color: 'success',
          title: `สำเร็จ`,
          text: 'สร้างผู้ใช้น้ำสำเร็จ',
          acceptText: 'ปิด'
        })
      }
    },
    async created() {
      this.$vs.loading()
      if (!moduleArea.isRegistered) {
        this.$store.registerModule('area', moduleArea);
        moduleArea.isRegistered = true
      }
      if (!moduleRoute.isRegistered) {
        this.$store.registerModule('route', moduleRoute);
        moduleRoute.isRegistered = true
      }
      if (!moduleMeter.isRegistered) {
        this.$store.registerModule('meter', moduleMeter);
        moduleMeter.isRegistered = true
      }
      if (!moduleDiscount.isRegistered) {
        this.$store.registerModule('discount', moduleDiscount);
        moduleDiscount.isRegistered = true
      }
      if (!modulePayby.isRegistered) {
        this.$store.registerModule('payby', modulePayby);
        modulePayby.isRegistered = true
      }
      if (!moduleUnuse.isRegistered) {
        this.$store.registerModule('unuse', moduleUnuse);
        moduleUnuse.isRegistered = true
      }
      if (!moduleRate.isRegistered) {
        this.$store.registerModule('rate', moduleRate);
        moduleRate.isRegistered = true
      }
      if (!moduleMember.isRegistered) {
        this.$store.registerModule('member', moduleMember)
        moduleMember.isRegistered = true
      }
      if(!moduleGroup.isRegistered) {
        this.$store.registerModule('group', moduleGroup)
        moduleGroup.isRegistered = true
      }
      await this.$store.dispatch("group/fetchDataListItems");
      await this.$store.dispatch("rate/fetchNameListItems");
      await this.$store.dispatch("unuse/fetchDataListItems");
      await this.$store.dispatch("payby/fetchDataListItems");
      await this.$store.dispatch("discount/fetchDataListItems");
      await this.$store.dispatch("meter/fetchDataListItems");
      await this.$store.dispatch("route/fetchDataListItems");
      await this.$store.dispatch("area/fetchDataListItems")
      await this.init()
    },
  }
</script>
